import { configureStore } from '@reduxjs/toolkit';
import userReducer, { UserState } from './redux/userSlice';

const getData = () => {
  let userData: UserState = { isLoggedIn: false };
  if (localStorage.getItem('user') !== null) {
    userData = JSON.parse(localStorage.getItem('user') || '{}');
  }
  return { user: userData };
};

// @ts-ignore
const localStorageMiddleware = ({ getState }) => (next: any) => (action: any) => {
  const result = next(action);
  localStorage.setItem('user', JSON.stringify(getState().user));
  return result;
};

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: getData(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
